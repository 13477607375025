import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'bumblebee-ui';
import { useLocation } from 'react-router-dom';

import { useGetMessagesQuery } from '../redux/reducers/messages';
import { history } from '../routers/AppRouter';

const config = stateConfig;

const NavBar = ({ isLoggedIn, isBannerVisible, onClickLogOut, onClickLogIn, isTranslatorOpened }) => {
  const { data: messages = [] } = useGetMessagesQuery(
    undefined,
    {
      skip: !isLoggedIn,
      pollingInterval: 120000, // refresh every 2 minutes
    },
  );

  const [activeDropdown, setActiveDropdown] = useState(false);
  const { pathname } = useLocation();

  const showLogin = config.topNav.isLoginBtnVisible;

  const renderResources = (config, isDesktop) => {
    if (!config.staticPages.resources?.isNavBar) return null;

    let resources;

    if (isDesktop) {
      resources = (
        <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/resources' ? 'active' : ''}`}>
          <Button
            ghost
            className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
            data-cy='resources'
            onClick={() => {
              config.staticPages.resources.isExternalLink
                ? window.location = config.staticPages.resources.externalURL
                : history.push('/resources');
            }}
          >
            <div>Resources</div>
          </Button>
        </li>
      );
    } else {
      resources = (
        <>
          <div className='dropdown-item' data-cy='mobile-resources'
            onClick={() => {
              setActiveDropdown(false);
              config.staticPages.resources.isExternalLink
                ? window.location = config.staticPages.resources.externalURL
                : history.push('/resources');
            }}>
            <div className='body p-3 nav-mobile-title ms-3'><span>Resources</span></div>
          </div>
          <div className='dropdown-divider'></div>
        </>
      );
    }

    if (isLoggedIn && config.staticPages.resources.isVisible.includes('loggedIn')) {
      return resources;
    }

    if (!isLoggedIn && config.staticPages.resources.isVisible.includes('loggedOut')) {
      return resources;
    }

    return null;
  };

  const renderTestimonials = (config, isDesktop) => {
    if (!config.staticPages.testimonials?.isNavBar) return null;

    let testimonials;

    if (isDesktop) {
      testimonials = (
        <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/testimonials' ? 'active' : ''}`}>
          <Button
            ghost
            className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
            data-cy='testimonials'
            onClick={() => {
              config.staticPages.testimonials.isExternalLink
                ? window.location = config.staticPages.testimonials.externalURL
                : history.push('/testimonials');
            }}>
            <div>Testimonials</div>
          </Button>
        </li>
      );
    } else {
      testimonials = (
        <>
          <div className='dropdown-item' data-cy='mobile-testimonials'
            onClick={() => {
              setActiveDropdown(false);
              config.staticPages.testimonials.isExternalLink
                ? window.location = config.staticPages.testimonials.externalURL
                : history.push('/testimonials');
            }}>
            <div className='body p-3 nav-mobile-title ms-3'><span>Testimonials</span></div>
          </div>
          <div className='dropdown-divider'></div>
        </>
      );
    }

    if (isLoggedIn && config.staticPages.testimonials.isVisible.includes('loggedIn')) {
      return testimonials;
    }

    if (!isLoggedIn && config.staticPages.testimonials.isVisible.includes('loggedOut')) {
      return testimonials;
    }

    return null;
  };

  const renderApplicationProcess = (config, isDesktop) => {
    if (!config.staticPages.applicationProcess?.isNavBar) return null;

    let applicationProcess;

    if (isDesktop) {
      applicationProcess = (
        <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/application-process' ? 'active' : ''}`}>
          <Button
            ghost
            className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
            data-cy='application-process'
            onClick={() => {
              config.staticPages.applicationProcess.isExternalLink
                ? window.location = config.staticPages.applicationProcess.externalURL
                : history.push('/application-process');
            }}
          >
            <div>Application Process</div>
          </Button>
        </li>
      );
    } else {
      applicationProcess = (
        <>
          <div
            className='dropdown-item'
            data-cy='mobile-application-process'
            onClick={() => {
              setActiveDropdown(false);
              config.staticPages.applicationProcess.isExternalLink
                ? window.location = config.staticPages.applicationProcess.externalURL
                : history.push('/application-process');
            }}>
            <div className='body p-3 nav-mobile-title ms-3'><span>Application Process</span></div>
          </div>
          <div className='dropdown-divider'></div>
        </>
      );
    }

    if (isLoggedIn && config.staticPages.applicationProcess.isVisible.includes('loggedIn')) {
      return applicationProcess;
    }

    if (!isLoggedIn && config.staticPages.applicationProcess.isVisible.includes('loggedOut')) {
      return applicationProcess;
    }

    return null;
  };

  const renderTreasuryReport = (config, isDesktop) => {
    if (!config.staticPages.treasuryReport?.isNavBar) return null;

    let treasuryReport;

    if (isDesktop) {
      treasuryReport = (
        <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/treasury-report' ? 'active' : ''}`}>
          <Button
            ghost
            className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
            data-cy='treasury-report'
            onClick={() => window.open(config.staticPages.treasuryReport.externalURL)}
          >
            {/* <div className={`border-bottom border-3 p-2 ${pathname.includes('/treasury-report') ? 'border-primary' : ''}`}> */}
            <div>Treasury Report</div>
            {/* </div> */}
          </Button>
        </li>
      );
    } else {
      treasuryReport = (
        <>
          <div
            className='dropdown-item'
            data-cy='mobile-treasury-report'
            onClick={() => {
              setActiveDropdown(false);
              window.open(config.staticPages.treasuryReport.externalURL);
            }}>
            <div className='body p-3 nav-mobile-title ms-3'><span>Treasury Report</span></div>
          </div>
          <div className='dropdown-divider'></div>
        </>
      );
    }

    if (isLoggedIn && config.staticPages.treasuryReport.isVisible.includes('loggedIn')) {
      return treasuryReport;
    }

    if (!isLoggedIn && config.staticPages.treasuryReport.isVisible.includes('loggedOut')) {
      return treasuryReport;
    }

    return null;
  };

  return (
    <>
      <nav className='navbar col-12 p-0 fixed-top d-flex flex-row' style={isBannerVisible ? { marginTop: '60px' } : {}}>
        <div className='navbar-menu-wrapper d-flex align-items-center justify-content-between' style={{ marginTop: isTranslatorOpened ? '37px' : '' }}>
          <div className='ms-3 pointer' data-cy='nav-logo' onClick={() => history.push('/')}>
            <div className='mb-0'>
              <img src={config.style.logo} style={{ maxHeight: '60px', maxWidth: '280px' }} alt='top nav logo' />
            </div>
          </div>
          {/* ----------- DESKTOP ----------- */}
          <ul className='navbar-nav'>
            {/* SKIP NAVIGATION */}
            <Button
              ghost
              size='xs'
              className='skip-link text-black f-regular mx-3 sr-only'
              data-cy='skip-nav'
              onClick={(e) => {
                e.preventDefault();
                const container = document.getElementById('app-wrapper');
                if (container) {
                  container.tabIndex = -1;
                  container.focus();
                  setTimeout(() => container.removeAttribute('tabindex'), 1000);
                }
              }}
            >Skip Navigation</Button>

            {/* WAITLIST */}
            {!isLoggedIn && config.waitlistPage.isVisible &&
              <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/waitlist' ? 'active' : ''}`}>
                <Button
                  ghost
                  className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                  data-cy='waitlist-page'
                  onClick={() => history.push('/waitlist')}>
                  <div>Join Waitlist</div>
                </Button>
              </li>
            }

            {/* FAQ */}
            {!isLoggedIn && config.faqPage.isVisible &&
              <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/faq' ? 'active' : ''}`} >
                <Button
                  ghost
                  className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                  data-cy='faq'
                  onClick={() => {
                    config.faqPage.isExternalLink
                      ? window.location = config.faqPage.externalURL
                      : history.push('/faq');
                  }}
                >
                  {/* <div className={`border-bottom border-3 p-2 ${pathname.includes('/faq') ? 'border-primary' : ''}`}> */}
                  <div>FAQs</div>
                  {/* </div> */}
                </Button>
              </li>
            }

            {/* REQUIRED DOCUMENTS */}
            {!isLoggedIn && config.requiredDocumentsPage.isVisible &&
              <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/required-documents' ? 'active' : ''}`}>
                <Button
                  ghost
                  className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                  data-cy='required-documents'
                  onClick={() => history.push('/required-documents')}
                >
                  {/* <div className={`border-bottom border-3 p-2 ${pathname.includes('/required-documents') ? 'border-primary' : ''}`}> */}
                  <div>Required Documents</div>
                  {/* </div> */}
                </Button>
              </li>
            }

            {/* REPORT FRAUD */}
            {!isLoggedIn && config.reportFraudPage.isVisible &&
              <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/report-fraud' ? 'active' : ''}`}>
                <Button
                  ghost
                  className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                  data-cy='report-fraud'
                  onClick={() => {
                    config.reportFraudPage.isExternalLink
                      ? window.location = config.reportFraudPage.externalURL
                      : history.push('/report-fraud');
                  }}
                >
                  {/* <div className={`border-bottom border-3 p-2 ${pathname.includes('/report-fraud') ? 'border-primary' : ''}`}> */}
                  <div>Report Fraud</div>
                  {/* </div> */}
                </Button>
              </li>
            }

            {/* TRUSTEE INQUIRY */}
            {!isLoggedIn && config.trusteeInquiryPage.isVisible &&
              <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/trustee' ? 'active' : ''}`}>
                <Button
                  ghost
                  className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                  data-cy='trustee-inquiry'
                  onClick={() => history.push('/trustee')}
                >
                  {/* <div className={`border-bottom border-3 p-2 ${pathname.includes('/trustee') ? 'border-primary' : ''}`}> */}
                  <div>Third-Party Inquiry</div>
                  {/* </div> */}
                </Button>
              </li>
            }

            {/* MY APPLICATION */}
            {isLoggedIn &&
              <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname.includes('/application') ? 'active' : ''}`}>
                <Button
                  ghost
                  className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                  data-cy='my-application'
                  onClick={() => history.push('/application')}
                >
                  <div className={`border-bottom border-3 p-2 ${pathname.includes('/application') ? 'border-primary' : ''}`}>
                    <span className='fas fa-file-pen me-2' />My Application
                  </div>
                </Button>
              </li>
            }

            {/* MESSAGE CENTER */}
            {isLoggedIn &&
              <li className={`nav-item d-none d-lg-flex justify-content-center ${pathname === '/message-center' ? 'active' : ''}`}>
                <Button
                  ghost
                  className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                  data-cy='message-center'
                  onClick={() => history.push('/message-center')}
                >
                  <div className={`border-bottom border-3 p-2 ${pathname.includes('/message-center') ? 'border-primary' : ''}`}>
                    <span className='fas fa-messages me-2' data-count={messages?.filter((d) => !d.is_read && d.sender_type === 'admin').length === 0 ? null : messages?.filter((d) => !d.is_read && d.sender_type === 'admin').length} />
                    Message Center
                  </div>
                </Button>
              </li>
            }

            {/* RESOURCES */}
            {renderResources(config, true)}

            {/* TESTIMONIALS */}
            {renderTestimonials(config, true)}

            {/* APPLICATION PROCESS */}
            {renderApplicationProcess(config, true)}

            {/* TREASURY REPORT */}
            {renderTreasuryReport(config, true)}

            {/* LOG IN/OUT */}
            {showLogin
              ? isLoggedIn
                ? <li className='nav-link px-3 d-none d-lg-flex'>
                  <Button
                    outline
                    size='sm'
                    className='mx-1 mx-sm-3'
                    data-cy='logout'
                    onClick={onClickLogOut}
                  >Logout</Button>
                </li>
                : <li className='nav-link px-3 d-none d-lg-flex'>
                  <Button
                    variant='success'
                    size='sm'
                    className='mx-1 mx-sm-3 text-white'
                    data-cy='login'
                    onClick={onClickLogIn}
                  >Login</Button>
                </li>
              : null
            }

            {/* ----------- TABLET/MOBILE ----------- */}
            <li className={`nav-item dropdown ${activeDropdown ? 'show selected' : ''} d-flex d-lg-none justify-content-center align-items-center`}>
              <div className='nav-link mobile-menu pointer' data-cy='mobile-dropdown' onClick={() => setActiveDropdown(!activeDropdown)}>
                <span className='nav-icon fas fa-bars' />
              </div>

              <div className={`navbar-dropdown dropdown-menu shadow fh-regular pointer ${activeDropdown ? 'show' : ''}`}>

                {/* WAITLIST */}
                {!isLoggedIn && config.waitlistPage.isVisible &&
                  <>
                    <div
                      className='dropdown-item'
                      data-cy='mobile-waitlist'
                      onClick={() => history.push('/waitlist')}
                    >
                      <div className='body p-3 nav-mobile-title ms-3'><span>Join Waitlist</span></div>
                    </div>
                    <div className='dropdown-divider'></div>
                  </>
                }

                {/* FAQ */}
                {!isLoggedIn && config.faqPage.isVisible &&
                  <>
                    <div
                      className='dropdown-item'
                      data-cy='mobile-faq'
                      onClick={() => {
                        setActiveDropdown(false);
                        config.faqPage.isExternalLink
                          ? window.location = config.faqPage.externalURL
                          : history.push('/faq');
                      }}
                    >
                      <div className='body p-3 nav-mobile-title ms-3'><span>FAQs</span></div>
                    </div>
                    <div className='dropdown-divider'></div>
                  </>
                }

                {/* REQUIRED DOCUMENTS */}
                {!isLoggedIn && config.requiredDocumentsPage.isVisible &&
                  <>
                    <div
                      className='dropdown-item'
                      data-cy='mobile-required-docs'
                      onClick={() => {
                        setActiveDropdown(false);
                        history.push('/required-documents');
                      }}
                    >
                      <div className='body p-3 nav-mobile-title ms-3'><span>Required Documents</span></div>
                    </div>
                    <div className='dropdown-divider'></div>
                  </>
                }

                {/* REPORT FRAUD */}
                {!isLoggedIn && config.reportFraudPage.isVisible &&
                  <>
                    <div
                      className='dropdown-item'
                      data-cy='mobile-report-fraud'
                      onClick={() => {
                        setActiveDropdown(false);
                        config.reportFraudPage.isExternalLink
                          ? window.location = config.reportFraudPage.externalURL
                          : history.push('/report-fraud');
                      }}
                    >
                      <div className='body p-3 nav-mobile-title ms-3'><span>Report Fraud</span></div>
                    </div>
                    <div className='dropdown-divider'></div>
                  </>
                }

                {/* TRUSTEE INQUIRY */}
                {!isLoggedIn && config.trusteeInquiryPage.isVisible &&
                  <>
                    <div
                      className='dropdown-item'
                      data-cy='mobile-trustee-inquiry'
                      onClick={() => history.push('/trustee')}
                    >
                      <div className='body p-3 nav-mobile-title ms-3'><span>Trustee Inquiry</span></div>
                    </div>
                    <div className='dropdown-divider'></div>
                  </>
                }

                {/* MY APPLICATION */}
                {isLoggedIn &&
                  <>
                    <div
                      className='dropdown-item'
                      data-cy='mobile-application'
                      onClick={() => {
                        setActiveDropdown(false);
                        history.push('/application');
                      }}
                    >
                      <div className='body p-3 nav-mobile-title ms-3'><span>My Application</span></div>
                    </div>
                    <div className='dropdown-divider'></div>
                  </>
                }

                {/* MESSAGE CENTER */}
                {isLoggedIn &&
                  <>
                    <div
                      className='dropdown-item'
                      data-cy='mobile-message-center'
                      onClick={() => {
                        setActiveDropdown(false);
                        history.push('/message-center');
                      }}
                    >
                      <div className='body p-3 nav-mobile-title ms-3'><span>Message Center</span></div>
                    </div>
                    <div className='dropdown-divider'></div>
                  </>
                }

                {/* RESOURCES */}
                {renderResources(config, false)}

                {/* TESTIMONIALS */}
                {renderTestimonials(config, false)}

                {/* APPLICATION PROCESS */}
                {renderApplicationProcess(config, false)}

                {/* TREASURY REPORT */}
                {renderTreasuryReport(config, false)}

                {/* LOG IN/OUT */}
                {showLogin
                  ? isLoggedIn
                    ? (
                      <div
                        className='dropdown-item'
                        data-cy='mobile-logout'
                        onClick={() => {
                          setActiveDropdown(false);
                          onClickLogOut();
                        }}
                      >
                        <div className='no-hover w-100 m-3'>
                          <Button
                            block
                            size='sm'
                            className='px-5 ms-3'
                          >
                            Log Out
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className='dropdown-item'
                        data-cy='mobile-login'
                        onClick={() => {
                          setActiveDropdown(false);
                          onClickLogIn();
                        }}
                      >
                        <div className='no-hover w-100 m-3'>
                          <Button
                            block
                            size='sm'
                            variant='success'
                            className='px-5 ms-3'
                          >
                            Log In
                          </Button>
                        </div>
                      </div>
                    )
                  : null
                }
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  isBannerVisible: PropTypes.bool,
  onClickLogOut: PropTypes.func,
  onClickLogIn: PropTypes.func,
  isTranslatorOpened: PropTypes.bool,
};

export default NavBar;
